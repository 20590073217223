body {
  margin: 0;
}
input[type="text"],
label {
  display: block;
  outline: none;
}
button:focus {
  outline: none;
  cursor: pointer;
}
a {
  color: black;
}
.Toastify__toast--success {
  background: #17a398 !important;
  border-radius: 8px !important;
  box-shadow: 0px -11px 40px #0000000f !important;
  padding: 8px !important;
}
.Toastify__toast--error {
  background: #b33e41 !important;
  border-radius: 8px !important;
  box-shadow: 0px -11px 40px #0000000f !important;
  padding: 8px !important;
}
._2iA8p44d0WZ-WqRBGcAuEV {
  min-height: 40px !important;
  padding: 5px !important;
  border: 1px solid black;
  overflow-y: scroll;
  max-height: 100px;
}
.chip {
  margin-right: 5px !important;
  padding: 0px 5px !important;
}
.checkbox {
  margin-right: 5px !important;
  margin-left: 5px !important;
}
#locationSelect {
  width: 200px;
}
#locationSelect ._3vLmCG3bB3CM2hhAiQX1tN {
  position: absolute !important;
}
._3vLmCG3bB3CM2hhAiQX1tN {
  position: sticky !important;
}

.job_listing_btn {
  padding: 10px;
  width: 20%;
  outline: none;
  border: none;
  border-radius: 4px;
  font-size: 16px;
}
